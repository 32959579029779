<template>
    <div>
      <h2>Закрытые лоты</h2>

      <el-divider></el-divider>

      <search-block title="лота">
        <el-row :gutter="10">
          <el-col span="12">
            <el-input v-model="input"  placeholder="Код/наименование отхода или ключевое слово"/>
          </el-col>
          <el-col span="3">
            <el-button type="primary" @click="searchLots(input)">Поиск</el-button>
          </el-col>
          <el-col span="3">
          </el-col>
          <el-col span="3">
          </el-col>
        </el-row>
      </search-block>
      <div class="lots-sort">
        <h4>Сортировать отходы по</h4>
        <el-select
      v-model="selectRegion"
    class="m-2"
    placeholder="Select"
    size="large"
    style="width: 240px"
  >
    <el-option
      v-for="region in regions"
      :key="region.id"
      :label="region.region"
      :value="region.id"
    />
  </el-select>

  <h4> </h4>


  <!-- <select v-model="selectPrice" @change="sortPrice">
    <option value="0">Все цены</option>
    <option v-for=" price in  prices " :key=" price.id" :value=" price.id">{{ price.price }}</option>
  </select> -->

  <el-select
  @change="sortPrice"
v-model="selectPrice"
    class="m-2"
    placeholder="По цене"
    size="large"
    style="width: 240px"
  >
    <el-option
      v-for="price in  prices"
      :key="price.id"
      :label="price.price"
      :value="price.id"
    />
  </el-select>
      </div>

      <div class="cards" :key="lot.lotsId" v-for="lot in ListLots">
        <el-card class="box-card" shadow="hover" v-if="lot.lotsId != null && (selectRegion == lot.oblast || selectRegion == 0)">
          <template #header>
            <div class="card-header">
              <div>Лот {{ lot.lotsId }}</div>
              <div>
                <div v-if="lot.disposal == 1">транспортом производителя</div>
                <div v-else-if="lot.disposal == 2">транспортом переработчика</div>
                <div v-else-if="lot.disposal == 3">
                  транспортом иной организации
                </div>
                <div v-else>error</div>
                <!-- {{ delivery.find((obj) => obj.id === lot.disposal) }}
                {{ delivery }} -->
              </div>
              <span
                >Сбор предложений: {{ (10 > new Date(lot.date_from).getDate()  ? '0':'')+new Date(lot.date_from).getDate()+'.'+(9>new Date(lot.date_from).getMonth() ? '0':'' )+(new Date(lot.date_from).getMonth()+1)+'.'+new Date(lot.date_from).getFullYear() }} &ndash;
                {{ (10 > new Date(lot.date_to).getDate()  ? '0':'')+new Date(lot.date_to).getDate()+'.'+(9>new Date(lot.date_to).getMonth() ? '0':'' )+(new Date(lot.date_to).getMonth()+1)+'.'+new Date(lot.date_to).getFullYear() }}</span
              >
            </div>
          </template>
          <el-row :gutter="10" class="test111">
            <el-col span="16" class="info">
            <!-- <el-col class="type">Покупка</el-col> -->
            <div class="card">

              <div class="card-left">
                <div class="card-left-location">{{ lot.adress }}</div>
              </div>
              <div class="card-left-waste-info">
                <div class="card-left-waste-info-name">
                  {{ (lot.waste_code==0?"":(lot.waste_code + " - ")) + lot.waste_name }}
                </div>
              </div>
              <div class="card-left-waste-quantity">
                <div class="card-left-waste-quantity-all-waste">
                  <div class="card-left-waste-quantity-total-descr">Стоимость:</div>
                  {{(lot.price_min+lot.price_max)==0?"":
                    lot.price_min +
                    " BYN/" +
                    (lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan") +
                    " - " +
                    lot.price_max +
                    "BYN/" +
                    (lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan")
                  }}
                </div>
                <div class="card-left-waste-quantity-cost">
                  <div class="card-left-waste-quantity-total-descr">Масса:</div>
                  {{ lot.mass_min }} {{
                    lot.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                          .name
                      : "Nan"
                  }}
                </div>

                <div class="card-left-waste-quantity-total">
                  <div class="card-left-waste-quantity-total-descr">Итого:</div>
                  <div class="card-left-waste-quantity-total-price">
                    {{
                      Math.round(lot.price_min * lot.mass_min*1000)/1000 +
                      " - " +
                      Math.round(lot.price_max * lot.mass_min*1000)/1000
                    }}
                  </div>
                  <div class="card-left-waste-quantity-total-currency">BYN</div>
                  <div class="card-left-waste-quantity-total-tax">
                    {{ lot.nds == true ? "с НДС" : "без НДС" }}
                  </div>
                </div>

              </div>
            </div>
          </el-col>
        <el-col span="8" class="received-applications">
          <div class="card-left-waste-info-name-received-applications">Принятые заявки:</div>

          <div v-if="lot.lotsBid.length>0">
            <div :key="Bid.id" v-for="Bid in lot.lotsBid" class="card-left-waste-info-name-received-applications-card">
              <div class="card-left-waste-info-name-received-applications-card-adress">{{ Bid.adress }}</div>
              <div class="card-left-waste-quantity-cost-received">{{ Bid.mass }} {{
                          lot.mass_max != 0
                            ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                                .name
                            : "Nan"
                        }}
              </div>
              <div class="card-left-waste-quantity-cost-received-mass" >
                {{
                          Bid.price +
                          " BYN/" +
                          (lot.mass_max != 0
                            ? pss93UnitsInfoList.find((obj) => obj.id === lot.mass_max)
                                .name
                            : "Nan")
                }}
              </div>
              <div>
                <el-button type="primary" style="padding:5px" @click="showInfo(Bid,lot)" >Получить информацию </el-button>
              </div>
            </div>

          </div>
        </el-col>
          </el-row>

        </el-card>
      </div>

      <custom-modal title="" :visible="showModal === 'infoGet'" v-if="showModal === 'infoGet'">
        <user-date :Bid="Bid" :lot="lot" :type="1"></user-date>
      </custom-modal>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  import UserDate from "@/components/ecologist/wasteExchange/userDate";
  import CustomModal from "@/components/customModal";
import SearchBlock from "@/components/searchBlock";
  export default {
    name: "wasteExchangeMain",
    components: { CustomModal, UserDate,SearchBlock },
    data() {
      return {
      selectPrice:"Все цены",
      selectRegion: 0,
        Bid:null,
        lot:null,
        input: null,
        value: null,
        ListLots: [
          {
            adress: null,
            date_delet: null,
            date_from: null,
            date_to: null,
            delet: false,
            disposal: null,
            done: false,
            lotsId: null,
            mass_max: null,
            mass_min: null,
            nds: false,
            price_max: null,
            price_min: null,
            reason_delet: null,
            text: null,
            user_id: null,
            waste_code: null,
            waste_name: null,
          },
        ],
        pss93UnitsInfoList: [{ id: null, name: null }],
        contacts: [
          { name: "Иванов Иван Иванович" },
          { tel: " +375447155048 " },
          { email: "mihail_zavod@mail.ru" },
        ],
        delivery: [
          {
            id: 1,
            label: "транспортом производителя",
          },
          {
            id: 2,
            label: "транспортом переработчика",
          },
          {
            id: 3,
            label: "транспортом иной организации",
          },
        ],
        options: ["value1", "value2", "value3"],
        type: [
          {
            id: 1,
            label: "Покупатель",
          },
          {
            id: 2,
            label: "Продавец",
          },
        ],
        users: ["Производитель", "Переработчик"],
        lots: ["Лот1", "Лот2", 3],
        selected: "value",
        units: [
          {
            value: "Option1",
            label: "тонн",
          },
          {
            value: "Option2",
            label: "штук",
          },
        ],
        operations: [
          {
            value: "Option1",
            label: "Покупка",
          },
          {
            value: "Option2",
            label: "Продажа",
          },
        ],
        regions: [
        {id: 0, region: 'Все области' },
        { id: 1, region: 'Брестская область' },
        { id: 2, region: 'Витебская область' },
        { id: 3, region: 'Гомельская область' },
        { id: 4, region: 'Гродненская область' },
        { id: 5, region: 'Минская область' },
        { id: 6, region: 'Могилёвская область' },
        { id: 7, region: 'Минск' },
      ],
      prices:[
        {id: 0, price: 'Все цены '},
        {id: 1, price: 'По возрастанию '},
        {id: 2, price: 'По убыванию'}
      ],
        sortsItems: [
          {
            value: "Option1",
            label: "Стоимость по возрастанию",
          },
          {
            value: "Option2",
            label: "Стоимость по убыванию",
          },
          {
            value: "Option3",
            label: "По дате подачи предложения",
          },
          {
            value: "Option4",
            label: "по ...",
          },
        ],
      };
    },
    computed: {
      ...mapGetters({
        showModal: "GETSHOWMODAL",
      }),
    },
    methods: {

      sortPrice()
    {
      if (this.selectPrice==2)
      this.ListLots=this.ListLots.sort(function (a, b) {
      if (a.price_min > b.price_min) {
        return -1;
      }
      if (a.price_min < b.price_min) {
        return 1;
      }
  // a должно быть равным b
      return 0;
      });
      if (this.selectPrice==1)
      this.ListLots=this.ListLots.sort(function (a, b) {
      if (a.price_max > b.price_max) {
        return 1;
      }
      if (a.price_max < b.price_max) {
        return -1;
      }
  // a должно быть равным b
      return 0;
      });
    },

      LotBidDon(lot){
        this.$store.dispatch("LotBidDon",lot).then(() => {
          this.$store.dispatch("getMainLots").then((response) => {
          this.ListLots = response.data;
          console.log(this.ListLots);
      });
      });

      },
      showInfo(Bid,lot) {
        this.Bid=Bid;
        this.lot=lot;
        this.$store.dispatch("setShowModal", "infoGet");
      },
      async getSubClassifierById(id) {
        let data = {
          id: id,
          size: 100,
          page: 0,
        };
        return await this.$store.dispatch(
          "getAllDataSubClassifierForSelect",
          data
        );
      },
      searchLots(str){
        this.$store.dispatch("searchLotsString", {input:str, done:true}).then((response) => {
          this.ListLots = response.data;
        });
      },
      // openAddOffer() {
      //   this.$store.dispatch("setShowModal", "disposalAddWaste1");
      // },
    },
    mounted() {
      this.$store.dispatch("getMainDoneLots").then((response) => {
        this.ListLots = response.data;
        console.log(this.ListLots);
      });
      this.getSubClassifierById(93).then((response) => {
        this.pss93UnitsInfoList = response.data.content;
      });
    },
  };
  </script>

  <style scoped>
.lots-search {
  display: flex;
  align-items: center;
}
.lots-sort {
  display: flex;
  align-items: center;

  margin-bottom: 30px;
  margin-top: 20px;
}
.lots-search h4,
.lots-sort h4 {
  margin-right: 10px;
}

.lots-sort select {
  width: 10vw;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #41bf2d;
}
.lots-sort select:hover {
  cursor: pointer;
  border: 2px solid #53e340;
}
.lots-search input:hover {
  cursor: pointer;
  border: 3px solid #53e340;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-header span {
  font-weight: 400;
  font-weight: bold;
  text-decoration: underline;
}
.card-header div {
  font-weight: 400;
  font-weight: bold;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  cursor: pointer;
}

/* .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.card-left {
  width: 80vw;
}
.card-right {
  width: 20vw;
}
.card-left-company-info {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}
.card-left-waste-info-name,
.card-left-waste-quantity-total-descr {
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 10px;
  color: black;
}
.card-left-waste-info-name {
  padding-top: 20px;
  padding-bottom: 20px;
}
.card-left {
  display: flex;
}
.card-left-company {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}
.card-left-location,
.card-left-waste-quantity-all-waste,
.card-left-waste-quantity-cost {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.card-left-waste-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-left-waste-quantity-total {
  display: flex;
  align-items: center;
}
.card-left-waste-quantity-total-price,
.card-left-waste-quantity-total-currency,
.card-left-waste-quantity-total-tax {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
}
.cards {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.add-offer {
  display: flex;
  justify-content: center;
}
.offer-card {
  width: 60vw;
  cursor: pointer;
}
/* стили за для карточки */
.input-waste-name {
  width: 30vw;
  margin-right: 10px;
}
.date {
  width: 20vw;
  margin-right: 10px;
}
.input-waste-code {
  width: 10vw;
  margin-right: 10px;
}

.off-price {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.off-price-checkbox {
  display: flex;
  flex-direction: column;
}
.date {
  width: 50vw;
  display: flex;
  align-items: center;
}
.dateIn {
  margin-bottom: 10px;

  margin-right: 10px;
}
.input-waste-compound {
  width: 50vw;
}
.delivery {
  width: 50vw;
}
.contact {
  display: flex;
  align-items: center;
}
.contact p {
  font-weight: bold;
  margin-right: 5px;
}
.public-offer {
  display: flex;
  justify-content: center;
}
.card-left-waste-info-name-received-applications{
  font-weight: bold;
  margin-bottom: 10px;
}
.card-left-waste-info-name-received-applications-card{

background: #FAFAFA;
/* background: #be0d0d; */
border-radius: 5px;
margin-bottom: 10px;
display: flex;
align-items: center;
}
.card-left-waste-info-name-received-applications-card-inner{
display: flex;
width: 100%;
align-items: center;
}
.card-left-waste-info-name-received-applications-card-button{
margin-right: 10px;
}
.test111{
  display: flex;
  /* align-items: center; */
}
.info{
width: 60%;
}
.received-applications{
width: 45%;
}
.card-left-waste-info-name-received-applications-card-adress{
  width: 60%;
}
.typeofWaste{
  transform: rotate(270deg);
  background-color: rgba(112, 247, 96, 0.2);
}
.type {
  background-color: #aef0a6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  border-radius: 5px;
}
.typeSell {
  background-color: #aef0a6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  border-radius: 5px;
}
.typeBuy{
  background-color: 64,158,255;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1vw;
  border-radius: 5px;
}
.type-rotate{
  transform: rotate(270deg);
}
.el-card .el-card__body{
  padding: 40px;
}
.el-card{
  /* box-shadow: -5px 6px 18px 5px rgba(64,158,255, 0.2); синий=продажа */
  box-shadow: -5px 6px 18px 5px rgba(103,194,58, 0.2) ;
  margin-bottom: 30px;
}
.elcCardGreen{
  /* box-shadow: -5px 6px 18px 5px rgba(64,158,255, 0.2); синий=продажа */
  box-shadow: -5px 6px 18px 5px rgba(103,194,58, 0.2) ;
  margin-bottom: 30px;
}
.elcCardBlue{
  /* box-shadow: -5px 6px 18px 5px rgba(64,158,255, 0.2); синий=продажа */
  /* box-shadow: -5px 6px 18px 5px rgba(103,194,58, 0.2) ; */
  margin-bottom: 30px;
}
.card-left-waste-quantity-cost-received{
width: 50px;
margin-right: 10px;
color: #a5a5a5;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.card-left-waste-quantity-cost-received-mass{
  width: 150px;
  color: #a5a5a5;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
}
</style>