<template>
  <div>
    <waste-exchange-main-confirm></waste-exchange-main-confirm>
  </div>
</template>

<script>
import WasteExchangeMainConfirm from "@/components/ecologist/wasteExchange/wasteExchangeMainConfirm";

export default {
  name: "wasteExchangeMainConfirmView",
  components: { WasteExchangeMainConfirm },
};
</script>

<style scoped></style>
